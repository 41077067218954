import React, { useContext } from "react";

import { Context } from "@reactivated";
import { Helmet } from "react-helmet-async";



interface IScaffoldProps {
    title: string;
    children: React.ReactNode;
}


export const Scaffold = ({ title, children }: IScaffoldProps) => {
    const { STATIC_URL } = useContext(Context);

    return (
        <>
            <Helmet  htmlAttributes={{ lang: 'en' }}>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content={"Hiperoy specializes in advanced crushing solutions for both mining and cement industries. We offer high-performance, semi-mobile crushers and impactors designed for maximum efficiency and durability. With flexible, easy-to-install equipment, we help mining and cement operations boost productivity, lower costs, and optimize performance in diverse environments."} />
                <meta name="keywords" content={"crushing solutions, mining, cement, crushers, impactors"} />

                <link rel="apple-touch-icon" sizes="180x180" href="/assets/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicon-16x16.png"/>
                <link rel="manifest" href="/assets/site.webmanifest"/>
                <link
                    rel="stylesheet"
                    type="text/css"
                    href={`${STATIC_URL}dist/index.css`}
                />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=Krona+One&display=swap" rel="stylesheet"/>
                <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet"/>
                <link href="https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap" rel="stylesheet" />
                <script
                    defer
                    crossOrigin="anonymous"
                    src={`${STATIC_URL}dist/index.js`}
                />
            </Helmet>


            {children}
        </>
    );
};
