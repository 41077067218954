import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import MillLinersBanner from "../components/MillLinersBanner";

import ContactInfo from "../components/ContactInfo";



export default (props: templates.MillLiners) => {
    return (
        <Scaffold title="Mill Liner">
            <Navbar/>
            <MillLinersBanner/>

            <div className="mx-12 sm:mx-10 md:mx-16  lg:px-32 my-10 sm:my-28">

            <div className="flex flex-col">
                <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">Features</h1>
                <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                    <li className="list-disc">Custom Design: Tailored to client specifications and mill requirements to ensure optimal fit and performance.</li>

                    <li className="list-disc">Durability: Made from high-quality metals and alloys, providing enhanced resistance to wear and impact.</li>

                    <li className="list-disc">Enhanced Performance: Designed to improve mill efficiency and grinding performance through advanced profile engineering.</li>

                    <li className="list-disc">Collaborative Design: Developed in collaboration with clients to address specific operational needs and enhance liner effectiveness.</li>

                    <li className="list-disc">Extended Service Life: Engineered to withstand the harsh conditions of mineral grinding, resulting in longer-lasting performance and reduced downtime.</li>

                    <li className="list-disc"> Efficiency Improvement: Helps in reducing energy consumption and improving throughput by optimizing the grinding process.</li>

                </ul>
            </div>
            <ContactInfo/>
            </div>
            <Footer/>
        </Scaffold>
    )
}