import React, { useState, useEffect } from 'react';

interface ICarouselProps {
  images: string[];
}

const Carousel: React.FC<ICarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchEndX, setTouchEndX] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStartX || !touchEndX) return;

    const touchDifference = touchStartX - touchEndX;

    if (touchDifference > 50) {
      nextSlide();
    } else if (touchDifference < -50) {
      prevSlide();
    }

    setTouchStartX(null);
    setTouchEndX(null);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const checkMobileScreen = () => {
      if (window.innerWidth <= 640) { 
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkMobileScreen();

    window.addEventListener('resize', checkMobileScreen);

    return () => {
      window.removeEventListener('resize', checkMobileScreen);
    };
  }, []);

  useEffect(() => {
    let autoSlide: NodeJS.Timeout;
    if (isMobile) {
      autoSlide = setInterval(() => {
        nextSlide();
      }, 3000);
    }

    return () => clearInterval(autoSlide);
  }, [currentIndex, isMobile]);

  if (!images || images.length === 0) {
    return <div>No images to display</div>;
  }

  return (
    <div
      className="relative w-full max-w-xl mx-auto"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="overflow-hidden">
        <div
          className="flex transition-transform duration-500"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className="w-full h-auto"
            />
          ))}
        </div>
      </div>

      {!isMobile && (
        <>
          <button
            className="hidden sm:block absolute top-1/2 left-0 transform -translate-y-1/2 -ml-8"
            onClick={prevSlide}
          >
            <img src="/assets/icons/LeftArrow.svg" className="w-full h-auto" alt="Previous" />
          </button>
          <button
            className="hidden sm:block absolute top-1/2 right-0 transform -translate-y-1/2 -mr-8"
            onClick={nextSlide}
          >
            <img src="/assets/icons/RightArrow.svg" className="w-full h-auto" alt="Next" />
          </button>
        </>
      )}
    </div>
  );
};

export default Carousel;
