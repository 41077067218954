import React, { useRef } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import ExpandableContent from "@client/components/ExpandableContent";
import SectorCard from "@client/components/SectorCard";

export default (props: templates.MiningEquipemnt) => {
  const scrollContainerRef = useRef(null);
  return (
    <Scaffold title="Machine">
      <Navbar scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="h-screen overflow-y-scroll sm:snap-y sm:snap-mandatory pt-0 sm:pt-20 scroll-smooth"
      >
        <section className="snap-start">
          <div className="relative">
            <div className="hidden sm:block">
              <div className="relative w-full  h-screen">
                <img
                  src={"/assets/products/machinery.webp"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="flex flex-col justify-start item-start absolute top-[16%] sm:top-[40%] left-[4%] sm:left-[10%] w-[80%]">
                <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl mb-0 sm:mb-5">
                  Machinery
                </h1>
                <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block mb-0 sm:mb-10">
                  Products / Machinery
                </p>

                <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                  We offer a complete range of mining equipment designed to
                  maximize efficiency and productivity, including crushers,
                  high-performance grinding mills, and mobile units like
                  track-mounted jaw and cone crushers. Built for strength and
                  reliability, our equipment ensures top performance even in
                  tough conditions, supporting peak efficiency throughout the
                  mining cycle.
                </p>
              </div>
            </div>

            <div className="block sm:hidden">
              <div className="relative w-full h-full">
                <img
                  src={"/assets/products/MachineryMob.png"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
                <h1 className="font-heading font-normal text-2xl">Machinery</h1>
                <p className="font-body text-sm mt-5 text-center">
                  Our machinery is designed to deliver reliable,
                  high-performance solutions for efficient material processing
                  in mining and cement industries.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="snap-start">
          <div className="flex flex-col items-center space-y-8 md:space-y-14 mb-10 pt-0 sm:pt-28">
            <h1 className="mb-6 sm:mb-18 text-2xl font-bold font-heading text-center">
              Catalogue
            </h1>

            <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 space-x-0 sm:space-x-4 md:space-x-16 px-8 sm:px-0">
              <SectorCard
                title="Ball Sorting"
                imageSrc="/assets/products/BallSortingCard.svg"
                link="/product/ball-sorting/"
                splitTitle={["Ball", "Sorting"]}
              />
              <SectorCard
                title="Ball Loading"
                imageSrc="/assets/products/BallLoadingCard.svg"
                link="/product/ball-loading/"
                splitTitle={["Ball", "Loading"]}
              />
            </div>

            <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 space-x-0 sm:space-x-4 md:space-x-16 px-8 sm:px-0">
              <SectorCard
                title="Crushers"
                imageSrc="/assets/products/CrushersCard.svg"
                link="/product/crushers/"
              />
              <SectorCard
                title="Mobile Series"
                imageSrc="/assets/products/MobileSCard.svg"
                link="/product/mobile-series/"
                splitTitle={["Mobile", "Series"]}
              />
            </div>

            <div className="flex justify-center px-8 sm:px-0">
              <SectorCard
                title="Semi Mobile Series"
                imageSrc="/assets/products/SemiMobileCard.svg"
                link="/product/semi-mobile-series/"
                splitTitle={["Semi Mobile", "Series"]}
              />
            </div>
          </div>

          <ContactInfo />
          <Footer />
        </section>
      </div>
    </Scaffold>
  );
};
