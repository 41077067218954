import React, { useRef, useState } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import GrindingBallsBanner from "../components/GrindingBallsBanner";
import ContactInfo from "@client/components/ContactInfo";
import ExpandableContent from "@client/components/ExpandableContent";
import Carousel from "@client/components/Carousel";

export default (props: templates.GrindingBalls) => {
  const scrollContainerRef = useRef(null);

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const images = [
    {
      src: "/assets/products/GringinBallGrade.svg",
      alt: "Grade",
    },
    {
      src: "/assets/products/BallsPhysicalFeature.svg",
      alt: "Grade and Physical aspects",
    },
    {
      src: "/assets/products/BallsSize.svg",
      alt: "Size Tolerances",
    },
  ];

  const openModal = (imageSrc: any) => {
    setSelectedImage(imageSrc);
    setModalOpen(true);
  };

  const steelcarousalimg = [
    "/assets/products/SteelExpImg.svg",
    "/assets/products/SteelExpImg1.svg",
    "/assets/products/SteelExpImg2.svg",
  ];
  return (
    <Scaffold title="Grinding Media">
      <Navbar scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="h-screen overflow-y-scroll sm:snap-y sm:snap-mandatory pt-0 sm:pt-20 scroll-smooth"
      >
        <section className="snap-start">
          <GrindingBallsBanner />
        </section>

        <section className="snap-start">
          <div className="mx-12 sm:mx-10 md:mx-16 lg:px-32 py-0 sm:py-28">
            <div className="flex flex-col">
              <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">
                Key Features
              </h1>
              <ul className="mb-6 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                <li className="list-disc">
                  Cast alloy grinding balls with high abrasion-resistance,
                  categorized under white cast grade .
                </li>
                <li className="list-disc">
                  Contain a specific percentage of chromium, featuring a
                  martensitic base structure and ultra-hard chrome carbides .
                </li>
                <li className="list-disc">
                  Four decades of expertise ensure a balance between strength
                  and hardness for end-use applications .
                </li>
                <li className="list-disc">
                  Achieves low wear, optimal grinding output, and reduced
                  specific consumption leading to minimized mill downtime and
                  maximum throughput .
                </li>
              </ul>
            </div>
            <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">
              <ExpandableContent
                title="High Chrome"
                content="Our clients value our High Chrome Grinding Media Balls for their effectiveness and durability, and they are used in a variety of industrial operations. These items are tailored to meet the needs of the customers. "
                additionalInfo={
                  <div>
                    <div className="flex flex-col md:flex-row items-center justify-center mx-6 sm:mx-12">
                      {/* Info Section */}
                      <div className="w-full sm:w-1/2 flex flex-col justify-center items-center md:items-start text-left text-sm mt-4 sm:mt-0 font-body italic mb-4 sm:mb-0">
                        <h3 className="mb-2 text-left sm:text-start md:text-left leading-8">
                          The following range of High Chrome Grinding Media
                          Balls are available:
                        </h3>
                        <ul className="list-none text-gray-700 space-y-3 text-left">
                          <li>Dimensions: 15 mm to 125 mm</li>
                          <li>Carbon : 1.25 to 3%</li>
                          <li>Hardness ranges from 58 to 68 HRC</li>
                          <li>Chrome : 3 to 30 %</li>
                        </ul>
                      </div>

                      {/* Image Section */}
                      <div>
                        <img
                          src={"/assets/products/ChromeExpImg.svg"}
                          alt="Product"
                          className="rounded-lg max-w-full"
                        />
                      </div>
                    </div>

                    <div className="my-10 mx-6 sm:mx-10">

                      <div className="pb-10">
                        <h3 className="mb-6 sm:mb-12 font-bold font-heading text-start">
                          Grade
                        </h3>
                        <div className="w-full overflow-auto touch-pan-x">
                          <img
                            className="w-[150%] sm:w-full max-w-none h-max sm:h-auto object-cover cursor-pointer"
                            src={images[0].src}
                            alt={images[0].alt}
                            onClick={() => openModal(images[0].src)}
                          />
                        </div>
                      </div>

                      <div className="pb-10">
                        <h3 className="mb-6 sm:mb-12 font-bold font-heading text-start">
                          Grade and Physical aspects
                        </h3>
                        <div className="w-full overflow-auto touch-pan-x">
                          <img
                            className="w-[150%] sm:w-full max-w-none h-auto cursor-pointer"
                            src={images[1].src}
                            alt={images[1].alt}
                            onClick={() => openModal(images[1].src)}
                          />
                        </div>
                      </div>

                      <div>
                        <h3 className="mb-6 sm:mb-12 font-bold font-heading text-start">
                          Size Tolerances
                        </h3>
                        <div className="w-full overflow-auto touch-pan-x">
                          <img
                            className="w-[150%] sm:w-full max-w-none sm:h-auto cursor-pointer"
                            src={images[2].src}
                            alt={images[2].alt}
                            onClick={() => openModal(images[2].src)}
                          />
                        </div>
                      </div>



                      {isModalOpen && (
                        <div
                          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
                          onClick={() => setModalOpen(false)}
                        >
                          <img
                            src={selectedImage}
                            alt="Zoomed"
                            className="max-w-full max-h-full cursor-pointer"
                            onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                }
              />

              <ExpandableContent
                title="Hyper Steel"
                content="Our company plays a major role in offering our clients with a high-quality range of Hyper Steel Grinding Media Balls. These items are made using high-quality raw materials such as metals and alloys and modern machinery, ensuring the end user's durability."
                additionalInfo={
                  <div className="flex flex-col md:flex-row items-center justify-center px-12">
                    {/* Image Section */}
                    <div className="w-full sm:w-1/2 flex justify-center sm:justify-start mr-2">
                      <Carousel images={steelcarousalimg} />
                    </div>

                    {/* Info Section */}
                    <div className="w-full sm:w-1/2 flex flex-col justify-center items-start text-left text-sm mt-4 sm:mt-0 sm:ml-8 font-body italic">
                      <h3 className="mb-2">Our range is as follows:</h3>
                      <ul className="list-none text-gray-700 space-y-2">
                        <li> Diameter: 15 mm to 150 mm</li>
                        <li>Specification: As per IS 6079</li>
                        <li> Hardness: 375 BHN to 500 BHN</li>
                      </ul>
                    </div>
                  </div>
                }
              />
            </div>

            <ContactInfo />
          </div>

          <Footer />
        </section>
      </div>
    </Scaffold>
  );
};
