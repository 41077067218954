import React from "react";

interface ISectorCardProps {
  title: string;
  imageSrc: string;
  link?: string;
  splitTitle?: string[];
}

const SectorCard: React.FC<ISectorCardProps> = ({ title, imageSrc, link, splitTitle }) => {


  const cardContent = (
    <div className="relative overflow-hidden flex items-center justify-center rounded-[20px] shadow-around-shadow">
      <span className="absolute left-[5%] text-black font-bold font-heading text-base sm:text-lg break-words w-[80%] whitespace-pre-wrap">
      {splitTitle ? (
          <div>
            <span className="block">{splitTitle[0]}</span>
            <span className="block">{splitTitle[1]}</span>
          </div>
        ) : (
          title
        )}
      </span>
      <img src={imageSrc} alt={title} className="w-full h-full" />
    </div>
  );

  return link ? <a href={link}>{cardContent}</a> : cardContent;
};

export default SectorCard;
