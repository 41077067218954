import React from "react";

const BodyLinerBanner = () => {
  return (
    <div className="relative">
      <div className="hidden sm:block">
        <div className="relative w-full  h-screen">
          <img
            src={"/assets/products/BodyLiner.webp"}
            alt="background"
            className="w-full h-full object-cover relative z-[-100]"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
          <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
        </div>

        <div className="flex flex-col justify-start item-start absolute top-[16%] sm:top-[40%] left-[4%] sm:left-[10%] w-[80%]">
          <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl mb-0 sm:mb-5">
            Body Liners
          </h1>
          <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block mb-0 sm:mb-10">
            Products / Body Liners
          </p>

          <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
            Our body liners are engineered with carefully selected alloys to
            effectively reduce wear caused by the air flow through the mill and
            the abrasive materials. The advanced design and high-quality
            materials used in our liners ensure outstanding resistance to
            erosion and abrasion. This results in a longer lifespan, consistent
            performance, and decreased maintenance requirements, ultimately
            improving efficiency and reducing overall operating costs.
          </p>
        </div>
      </div>

      <div className="block sm:hidden">
        <div className="relative w-full h-full">
          <img
            src={"/assets/products/BodyLinersMob.png"}
            alt="background"
            className="w-full h-full object-cover relative z-[-100]"
          />
          <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
        </div>

        <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
          <h1 className="font-heading font-normal text-2xl">Body Liners</h1>
          <p className="font-body text-sm mt-5 text-center">
            Body liners provide essential protection and wear resistance in
            mills, ensuring reliable performance and extended equipment life.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BodyLinerBanner;
