import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#FAFAFA]  p-8 sm:p-6 md:p-22 mt-16 font-body">
      <div className="hidden sm:flex flex-row justify-center item-center text-sm md:text-md py-20">
        {/* <!-- Logo and Name --> */}
        <div className="flex flex-col items-center space-y-8 mx-4 lg:mx-16 xl:mx-24">
          <a href={"/"}>
            <img
              src={"/assets/icons/FooterLogo.svg"}
              className="w-[50%] h-[50%] sm:w-full sm:h-full"
            />
          </a>

          <p>© 2024 | Hiperoy Tech. | All Rights Reserved</p>
        </div>

        {/* <!-- Navigation Links --> */}
        <div className="flex flex-col items-start space-y-4 mx-4 lg:mx-8 xl:mx-12">
          <a href={"/"} className="hover:text-[#FC623D]">
            Home
          </a>
          <a href="/about" className="hover:text-[#FC623D]">
            About Us
          </a>
          <a href={"/product"} className="hover:text-[#FC623D]">
            Products
          </a>
          <a href="/#contact-section" className="hover:text-[#FC623D]">
            Contact Us
          </a>
        </div>

        {/* <!-- Address --> */}

        <div className="flex flex-col items-start space-y-6 mx-4 lg:mx-8 xl:mx-12 font-body">
          <div className="space-y-1">
            <p>+61 - 419 532 641</p>
            <p>sales@hiperoy.com.au</p>
          </div>

          <div className="space-y-1">
            <p>13 Schonell Way</p>
            <p>Piara Waters, Perth</p>
            <p>WA, 6112</p>
          </div>

          <div className="flex flex-row space-x-4">
            <img
              src={"/assets/icons/Facebook.svg"}
              className="w-[50%] h-[50%] sm:w-full sm:h-full"
            />

            <img
              src={"/assets/icons/Instagram.svg"}
              className="w-[50%] h-[50%] sm:w-full sm:h-full"
            />

            <img
              src={"/assets/icons/Linkdin.svg"}
              className="w-[50%] h-[50%] sm:w-full sm:h-full"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:hidden space-y-6 justify-center items-center">
        <a href={"/"}>
          <img src={"/assets/icons/FooterLogo.svg"} className="w-28 h-auto" />
        </a>

        <div className="text-center space-y-2 text-sm">
          <p>13 Schonell Way</p>
          <p>Piara Waters, Perth</p>
          <p>WA, 6112</p>
        </div>

        <div className="flex flex-row justify-center space-x-4">
          <img src="/assets/icons/Facebook.svg" className="w-full h-full" />
          <img src="/assets/icons/Instagram.svg" className="w-full h-full" />
          <img src="/assets/icons/Linkdin.svg" className="w-full h-full" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
