import React from "react";

const GrindingBallsBanner = () => {
  return (
    <div className="relative">
      <div className="hidden sm:block">
        <div className="relative w-full h-full h-screen">
          <img
            src={"/assets/products/Balls.svg"}
            alt="background"
            className="w-full h-full object-cover relative z-[-100]"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
          <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
        </div>

        <div className="flex flex-col justify-start item-start absolute top-[16%] sm:top-[40%] left-[4%] sm:left-[10%] w-[80%]">
          <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl mb-0 sm:mb-5">
            Grinding Media
          </h1>
          <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block mb-0 sm:mb-10">
            Products / Grinding Media
          </p>

          <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
            Our grinding balls deliver high abrasion-resistant grinding with a
            martensitic base and ultra-hard chrome carbides for strength and
            durability. Tailored to your needs, they ensure peak efficiency and
            minimal wear, reducing consumption, breakage, and mill downtime for
            increased productivity.
          </p>
        </div>
      </div>

      <div className="block sm:hidden">
        <div className="relative w-full h-full">
          <img
            src={"/assets/products/GrindingMediaMob.png"}
            alt="background"
            className="w-full h-full object-cover relative z-[-100]"
          />
          <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
        </div>

        {/* Text content positioned over the image */}
        <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
          <h1 className="font-heading font-normal text-2xl">Grinding Media</h1>
          <p className="font-body text-sm mt-5 text-center">
            Our grinding media deliver exceptional performance with high
            abrasion-resistant grinding, strength, and durability, ensuring peak
            efficiency, minimal wear, and reduced mill downtime.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GrindingBallsBanner;
